import React, { useEffect, useState } from 'react';
import GrowthChart from './growth_chart';
import { Col, Row, Space, Tag } from 'antd';
import numberHelper from '../../../../helpers/functions/number.helper';
import { FallOutlined, RiseOutlined, } from '@ant-design/icons';

const TicketLayout = ({ total_date_breakdown_sales, total_sales, compare, }) => {

    return (
        <>
            <div style={{marginTop : 24,}}>
                <div>
                    <Row gutter={[12, 12]}>
                        <Col span={6} xs={12} sm={12} md={12} xl={6}>
                            <div style={{textAlign:'start',}}>
                                <div>
                                    <div>
                                        <span style={{color : 'var(--secondary-text-color)', fontWeight:500,}}>Ticket Count</span>
                                    </div>
                                    <div>
                                        <Space>
                                            <div>
                                                <span style={{ fontSize: 24, }}>{numberHelper?.commaNumber(total_sales?.ticket_user_count, 0)}</span>
                                            </div>
                                            <div>
                                                <Tag color={ compare?.sale_count_increased >= 0 ? 'green' : 'red' } style={{border:'none',}}>
                                                    {compare?.sale_count_increased >= 0 ? <RiseOutlined /> : <FallOutlined />}
                                                    <span>{`${numberHelper?.commaNumber((Math.abs(compare?.sale_count_increased) * 100), 2)}%`}</span>
                                                </Tag>
                                            </div>
                                        </Space>
                                        
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={6} xs={12} sm={12} md={12} xl={6}>
                            <div style={{textAlign:'start',}}>
                                <div>
                                    <span style={{color : 'var(--secondary-text-color)', fontWeight:500,}}>Add On Count</span>
                                </div>
                                <div>
                                    <Space>
                                        <div>
                                            <span style={{ fontSize: 24, }}>{numberHelper?.commaNumber(total_sales?.add_on_count, 0)}</span>
                                        </div>
                                        <div>
                                            <Tag color={ compare?.add_on_count_increased >= 0 ? 'green' : 'red' } style={{border:'none',}}>
                                                {compare?.add_on_count_increased >= 0 ? <RiseOutlined /> : <FallOutlined />}
                                                <span>{`${numberHelper?.commaNumber((Math.abs(compare?.add_on_count_increased) * 100), 2)}%`}</span>
                                            </Tag>
                                        </div>
                                    </Space>
                                    
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div style={{marginTop:24,}}>
                    <GrowthChart total_date_breakdown_sales={total_date_breakdown_sales} />
                </div>
            </div>
        </>
    );
}

export default TicketLayout;