import { combineReducers } from 'redux';

//reducers
import authReducer from './auth.reducer';

const allReducers = combineReducers({
    auth : authReducer,
});

const rootReducer = (state, action) => {
    return allReducers(state, action);
}

export default rootReducer;