import React, { useEffect, useState } from 'react';

const ChangePasswordSuccess = () => {
    const [state, setState] = useState({
        timeout : 3,
    })

    useEffect(() => {
        let timeout = state.timeout;
        const interval = setInterval(() => {
            const minus_timeout = timeout - 1;
            setState(state => ({...state, timeout : minus_timeout}));
        }, 1000);

        if(timeout <= 0){
            window.location.reload(false);
        }

        return () => {
            // clearTimeout(countdown);
            clearInterval(interval);
          };
    }, [state?.timeout]);

    return (
        <>
            <div>
                <span>Redirecting you to dashboard page, please wait for {state?.timeout} seconds</span>
            </div>
        </>
    );
}

export default ChangePasswordSuccess;