import * as XLSX from "xlsx";

function generateSeed(min, max){
    const min_seed = min ?? 0;
    const max_seed = max ?? 1000;
    return Math.floor(Math.random() * (max_seed - min_seed + 1)) + min_seed;
}

function isMobileScreen(){
    const isMobile = window.innerWidth <= 768;

    return isMobile;
}

function _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

function groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
            acc[key] = [];
        }
        // Add object to list for given key's value
        acc[key].push(obj);
        return acc;
    }, {});
}

function tryParseJSONObject (jsonString){
    try {
        var o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object", 
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return o;
        }
    }
    catch (e) { }

    return false;
};


function exportToExcel({data, additional_data, name}) {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([]);

    // Insert additional row before data
    let additional_row_count = 0;
    if(additional_data){
        XLSX.utils.sheet_add_aoa(worksheet, additional_data);
        // Set the starting row to 
        additional_row_count = additional_data?.length;
        
    }

    XLSX.utils.sheet_add_json(worksheet, data, {
        origin: { r: additional_row_count , c: 0 },
    });
    
    
    // Adjust column widths
    const wscols = [];
    for (const key in data[0]){
        const first_data = data[0][key]; //take the first row to set the width
        wscols.push({ wch: first_data?.toString().length + 2});
    }
    worksheet['!cols'] = wscols;
    
    XLSX.utils.book_append_sheet(workbook, worksheet, 'data');
    XLSX.writeFile(workbook, `${name}.xlsx`);
}

function generateFixedColorFromString(inputString) {
    // Simple hash function
    let hash = 0;
    if (inputString.length === 0) return '#000000';
  
    for (let i = 0; i < inputString.length; i++) {
      const char = inputString.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash &= hash; // Convert to 32-bit integer
    }
  
    // Convert the hash to a color (hexadecimal value)
    const color = '#' + ('000000' + (hash & 0xFFFFFF).toString(16)).slice(-6);
  
    return color;
}

export default {
    generateSeed,
    isMobileScreen,
    _base64ToArrayBuffer,
    groupBy,
    tryParseJSONObject,
    exportToExcel,
    generateFixedColorFromString,
}