import React, { useEffect, useState } from 'react';
import saleService from '../../../services/affiliate/sale.service';
import { useSelector } from 'react-redux';
import { Space, Spin, Table, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import serviceHelper from '../../../helpers/functions/service.helper';

const TicketList = ({ filter, default_filter, is_paginate, display_size, }) => {
    const authRedux = useSelector(state => state.auth);
    const park = authRedux?.selected_promotion_code?.parks;
    const [state, setState] = useState({
        loading : false,
        tickets : [],

        // pagination
        count : 0,
        page : default_filter?.page ?? 1,
        display_size : display_size ?? 10,
    })

    const fetchSaleListing = async () => {
        setState(state => ({...state, loading : true, }));
        const { promotion_code_id } = authRedux?.selected_promotion_code;
        const { start_date, end_date, search, } = filter ?? {};
        const ticketListingResponse = await saleService?.ticketListing({
            promotion_code_id,
            page : (state?.page - 1),
            size : (state?.display_size),

            search,
            // payment start date and end date
            start_date, end_date,
        });

        const { tickets } = ticketListingResponse;
        setState(state => ({...state, loading : false, tickets : tickets?.rows, count : tickets?.count, }));
    }

    const paginateChange = (key, value) => {
        // set state
        setState(state => ({...state, [key] : value}));

        let generate_filter = filter ?? {};
        generate_filter[key] = value;
        const queryString = serviceHelper?.queryString(generate_filter);
        window.history.replaceState({}, '', `${window.location.pathname}?${queryString}`);
    }

    useEffect(() => {
        authRedux?.selected_promotion_code && 
        fetchSaleListing();
    }, [filter, state?.page])
    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <Table 
                scroll={{ x : 400, }}
                className='hub-table bordered'
                dataSource={state?.tickets}
                pagination={is_paginate ? {
                    size : state?.display_size,
                    // current : (ticketRedux?.count > state?.display_size) ? state?.page : 1, 
                    current : state?.page ?? 1,
                    total : state?.count,
                    showSizeChanger: false,
                    onChange : (page) => paginateChange('page', page)
                } : false }
                columns={[{
                    title : 'Transaction No',
                        dataIndex : 'masked_unique_no',
                        key : 'masked_unique_no',
                        render : (running_number, row, index) => {
                            return (
                                <>
                                {running_number}
                                </>
                            )
                        }
                },{
                    title : 'Park',
                    dataIndex : 'park',
                    key : 'park',
                    render : (park, row, index) => {
                        const { name, } = park;
                        const { ticket_type, } = row;
                        return (
                            <>
                                <div>
                                    <div>
                                        <span>{name}</span>
                                    </div>
                                    <div>
                                        <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{ticket_type?.name}</span>
                                    </div>
                                </div>
                            </>
                        )
                    }
                },
                // {
                //     title : 'Purchaser',
                //     dataIndex : 'name',
                //     key : 'name',
                //     render : (name, row, index) => {
                //         const { email, } = row;
                //         return (
                //             <>
                //                 <div>
                //                     <div>
                //                         <span>{name}</span>
                //                     </div>
                //                     <div>
                //                         <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{email}</span>
                //                     </div>
                //                 </div>
                //             </>
                //         )
                //     }
                // },
                {
                    title : (
                        <>
                            <Space>
                                <div>
                                    Sales Amount
                                </div>
                                <div>
                                    <Tag color='blue' style={{border:'none',}}>
                                        {park?.currency?.code}
                                    </Tag>
                                </div>
                            </Space>
                        </>
                    ),
                    dataIndex : 'payment_amount',
                    key : 'payment_amount',
                    render : (payment_amount, row, index) => {
                        const { payment_date } = row;
                        return (
                            <>
                                <div>
                                    <div>
                                        <span>{`${park?.currency?.symbol}${payment_amount}`}</span>
                                    </div>
                                    <div>
                                        <Tag color='green' style={{border:'none',}}>
                                            <span>{`Paid: ${moment(payment_date)?.format("YYYY-MM-DD hh:mm:ssa")}`}</span>
                                        </Tag>
                                    </div>
                                    {/* <div>
                                        <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{email}</span>
                                    </div> */}
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Ticket Count',
                    dataIndex : 'ticket_user_count',
                    key : 'ticket_user_count',
                    render : (ticket_user_count, row, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                    style={{
                                        background : 'var(--secondary-button-color)',
                                        borderRadius:'50%',
                                        width : 30,
                                        height : 30,
                                        display: 'flex',
                                        justifyContent : 'center',
                                        alignItems : 'center',
                                    }}>
                                        <span style={{color:'var(--secondary-text-color)', fontWeight:500,}}>{ticket_user_count}</span>
                                    </div>
                                </div>
                            </>
                        )
                    }
                },]}
                />
            </Spin>
        </>
    );
}

export default TicketList;