import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import numberHelper from '../../../../helpers/functions/number.helper';

const SaleChart = ({ total_date_breakdown_sales }) => {
    const canvasRef = useRef();
    const [state, setState] = useState({
        loading : false,
        data : null,
    })

    var options = {
        responsive: true,
        datasetStrokeWidth : 3,
        pointDotStrokeWidth : 4,
        maintainAspectRatio : false,
        scaleLabel : "1",
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                display : false,
            },
        },
        scales : {
            x: {
                ticks: {
                    display: true,
                },
            },
            y: {
                beginAtZero : true,
                grid : {
                    drawBorder: false,
                    display: false,
                },
                ticks: {
                    display: false
                }
            }
        }
        
    };
    const height = 180;
    const width = "100%";

    const initializeSalesChart = async () => {
        // const gradient = canvasRef.current.ctx.createLinearGradient(0, 0, 0, height);
        // gradient.addColorStop(0, 'rgba(239, 219, 255, 1)');   
        // gradient.addColorStop(1, 'rgba(239, 219, 255, 0)');
        const data = {
            labels: total_date_breakdown_sales?.map(x => moment(x.date)?.format('D MMM')),
            datasets : [{
                label : 'Sales Amount (MYR)',
                lineTension : 0.4,
                // fill: 'origin',
                // backgroundColor: 'red',
                // backgroundColor : gradient, // Put the gradient here as a fill color
                borderColor : '#13c2c2',
                borderWidth: 2,
                pointColor : "#fff",
                pointStrokeColor : '#13c2c2',
                pointBackgroundColor : '#bae0ff',
                pointHighlightFill: "#fff",
                pointHighlightStroke: '#13c2c2',
                data : total_date_breakdown_sales?.map(x => (((x?.date_sales_amount)))),
            }]
        }

        return data;
    }
    useEffect(() => {
        if(total_date_breakdown_sales?.length > 0){
            initializeSalesChart().then((data) => {
                setState(state => ({...state, data : data}));
            })
        }
    }, [total_date_breakdown_sales]);

    return (
        <>
            <div style={{width : width, height : height, }}>
                <Line
                ref={canvasRef}
                options={options}
                data={( state?.data ?? {
                    labels : [],
                    datasets : [],
                })}
                />
            </div>
        </>
    );
}

export default SaleChart;