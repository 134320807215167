import { Alert, Button, Divider, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import authService from '../../../services/affiliate/auth.service';
import { CheckCircleFilled, CloseCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import EntranceMotion from '../../../helpers/components/entrance_motion';

const Register = ({ affiliate_code, }) => {
    const history = useHistory();
    const [timer, setTimer] = useState(null);
    const [state, setState] = useState({
        save_loading : false,
        loading : false,
        affiliate : null,

        save_loading : false,


        // check existing
        is_existing : false,
        check_loading : false,
        username : null,
    });
    const [form] = Form.useForm();

    const onRegister = async () => {
        setState({...state, save_loading : true,})
        const { username, password, full_name } = form?.getFieldsValue();

        const updateLoginResponse = await authService?.updateAffiliateAccountAndLogin(affiliate_code, {
            username, password, full_name,
        }).catch(err => {
            setState(state => ({...state, save_loading : false,}));
        });

        if(updateLoginResponse?.token){
            //success login
            setTimeout(() => {
                setState(state => ({...state, save_loading : false, }));

                window.location.reload(false);
                
            }, 500);
        }
    }

    const fetchAffiliate = async () => {
        setState(state => ({...state, loading : true,}));
        const affiliateResponse = await authService?.getAffiliateByCode(affiliate_code, {
            affiliate_code_type : 'REG',
        }).catch((err) => {
            setState(state => ({...state, loading : false,}));
        });

        setTimeout(() => {
            const { affiliate } = affiliateResponse;
            setState(state => ({...state, loading : false, affiliate : affiliate }));
        }, 500);
    }

    const checkUsernameExisting = async (username) => {
        setState(state => ({...state, check_loading : true, }));
        const existingResponse = await authService?.checkUsernameExisting(username);

        setState(state => ({...state, check_loading : false, is_existing : existingResponse?.is_existing, username : username, }));
    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(async () => {
            // check existing
            if(input){
                await checkUsernameExisting(input);
            }else{
                setState(state => ({...state, check_loading : false, is_existing : false, username : null}))
            }
        }, 300);
        setTimer(newTimer);
    }

    useEffect(() => {
        fetchAffiliate();
    }, [])

    return (
        <>
            {
                state?.loading ?
                (
                    <>
                        <LoadingOutlined />
                    </>
                )
                :
                (
                    <>
                        {
                            state?.affiliate ?
                            (
                                <>
                                    <Form
                                    form={form}
                                    onFinish={onRegister}
                                    layout='vertical'
                                    // size={20}
                                    >
                                        <div style={{display:'flex', flexDirection : 'column', height:600, }}>
                                            <div>
                                                <div style={{marginTop:32,}}>
                                                    <div style={{textAlign:'start',}}>
                                                        <span
                                                        className='ticket-label ticket-title h1'
                                                        style={{
                                                            fontWeight:500,
                                                            fontSize: 20,
                                    
                                                        }}
                                                        >
                                                            Create Account
                                                        </span>
                                                    </div>
                                                    <div style={{textAlign:'start',}}>
                                                        <span style={{fontWeight:500, color : 'var(--secondary-text-color)'}}>{state?.affiliate?.email}</span>
                                                    </div>
                                                </div>


                                                <div style={{marginTop:42,}}>
                                                        {
                                                            state?.is_existing &&
                                                            (
                                                                <>
                                                                    <div style={{marginBottom:12,}}>
                                                                        <EntranceMotion>
                                                                            <Alert message={'Invalid username, please try another.'} type='error' />
                                                                        </EntranceMotion>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    
                                                    <Form.Item name={'username'} label={(<><span style={{fontSize:12, color:'var(--secondary-text-color)'}}>Username</span></>)}>
                                                        <Input
                                                        onChange={(e) => doneTyping(e.target?.value)}
                                                        size='large'
                                                        suffix={
                                                            (
                                                                state?.check_loading ?
                                                                (
                                                                    <>
                                                                        <LoadingOutlined />
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    <>
                                                                        {
                                                                            state?.username && 
                                                                            (state?.is_existing ? <CloseCircleFilled style={{color:'#ff7875'}} /> : <CheckCircleFilled style={{color:'#95de64'}}/>)
                                                                        }
                                                                    </>
                                                                )
                                                                
                                                            )
                                                        }
                                                        />
                                                    </Form.Item>
                                                    
                                                    <Form.Item name={'password'} label={(<><span style={{fontSize:12, color:'var(--secondary-text-color)'}}>Password</span></>)}>
                                                        <Input size='large' type='password'/>
                                                    </Form.Item>

                                                    <Divider plain>
                                                        <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>Optional</span>
                                                    </Divider>

                                                    <Form.Item name={'full_name'} label={(<><span style={{fontSize:12, color:'var(--secondary-text-color)'}}>Full Name</span></>)}
                                                    >
                                                        <Input size='large'/>
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div style={{marginTop:'auto',}}>
                                                <Form.Item>
                                                    <Button disabled={state?.is_existing} loading={state?.save_loading} htmlType='submit' type='primary' size='large' style={{width:'100%'}}>
                                                        Continue
                                                    </Button>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </Form>
                                </>
                            )
                            :
                            (
                                <>
                                    <div>
                                        <span style={{color:'var(--secondary-text-color)'}}>No affiliate found</span>
                                    </div>
                                    <div>
                                        <span style={{color:'var(--secondary-text-color)'}}>Please contact admin for affiliate invitation</span>
                                    </div>
                                </>
                            )
                        }
                        
                    </>
                )
            }
            
        </>
    );
}

export default Register;