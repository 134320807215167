import React from 'react';
import { Switch } from 'react-router-dom';
import PublicRoute from './public.router';
import PrivateRoute from './private.router';
import LayoutIndex from '../layouts';

// Page
import HomeIndex from '../containers/home';
import SaleIndex from '../containers/sale';

const AppRouter = () => {
    return (
        <>
            <LayoutIndex>
                <Switch>
                    {/* Home */}
                    <PrivateRoute component={HomeIndex} path="/" exact restricted={false} />
                    
                    {/* Sale */}
                    <PrivateRoute component={SaleIndex} path="/detail" exact restricted={false} />
                </Switch>
            </LayoutIndex>
        </>
    );
}

export default AppRouter;