import { Alert, Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import authService from '../../../services/affiliate/auth.service';
import { Link } from 'react-router-dom';
import EntranceMotion from '../../../helpers/components/entrance_motion';

const Login = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [state, setState] = useState({
        login_loading : false,
        invalid_credential : false,
    })
    const onLogin = async () => {
        setState(state => ({...state, login_loading : true,}));
        const { username, password } = form.getFieldsValue();
        const loginResponse = await authService.login({
            username : username,
            password : password,
        }).catch(err => {
            setState(state => ({...state, login_loading : false,}));
        });

        setState(state => ({...state, login_loading : false,}));
        if(loginResponse?.token){
            //success login
            window.location.reload(false);
        }else{
            // invalid credential
            setState(state=> ({...state, invalid_credential : true, }));
        }
    }
    return (
        <>
            <Form
            form={form}
            onFinish={onLogin}
            layout='vertical'
            // size={20}
            >
                <div style={{display:'flex', flexDirection : 'column', height:450, }}>
                    <div>
                        <div style={{marginTop:32,}}>
                            <div style={{textAlign:'start',}}>
                                <span
                                className='ticket-label ticket-title h1'
                                style={{
                                    fontWeight:500,
                                    fontSize: 20,
            
                                }}
                                >
                                    Enter your credentials
                                </span>
                            </div>
                        </div>

                        <div style={{marginTop:42,}}>
                            <Form.Item name={'username'} label={(<><span style={{fontSize:12, color:'var(--secondary-text-color)'}}>Username</span></>)}>
                                <Input size='large'/>
                            </Form.Item>

                            <Form.Item name={'password'}
                            extra={(<><Link to="/forget"><div style={{textAlign:'start', marginTop:8,}}><span style={{fontSize:12, color:'var(--main-color)',}}>Forgot your password?</span></div></Link></>)}
                            label={(<><span style={{fontSize:12, color:'var(--secondary-text-color)'}}>Password</span></>)}
                            >
                                <Input size='large' type='password'/>
                            </Form.Item>
                        </div>
                    </div>
                    {
                        state?.invalid_credential &&
                        (
                            <>
                                <EntranceMotion>
                                    <Alert message={"Invalid Credential"} type='error' />
                                </EntranceMotion>
                            </>
                        )
                    }

                    <div style={{marginTop:'auto',}}>
                        <Form.Item>
                            <Button loading={state?.login_loading} htmlType='submit' type='primary' size='large' style={{width:'100%'}}>
                                Continue
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </>
    );
}

export default Login;