import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import ForgetPasswordEmail from './forget_password_email';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import ForgetPasswordOtp from './forget_password_otp';
import ForgetPasswordChange from './forget_password_change';
import ChangePasswordSuccess from './change_password_success';

const ForgetPasswordLayout = () => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        step : 'email',

        email : null,
        affiliate_account_id : null,
        otp : null,
    });

    const nextStep = (step, key_value ) => {
        setState(state => ({...state, step : step, ...key_value, }));
    }

    return (
        <>
            <div>            
                {
                    state?.step == 'email' ?
                    (
                        <>
                            
                            <ForgetPasswordEmail nextStep={nextStep} />
                        </>
                    )
                    : state?.step == 'otp' ?
                    (
                        <>
                            <ForgetPasswordOtp nextStep={nextStep} email={state?.email} affiliate_account_id={state?.affiliate_account_id} />
                        </>
                    )
                    : state?.step == 'password' ?
                    (
                        <>
                            <ForgetPasswordChange nextStep={nextStep} otp={state?.otp} />
                        </>
                    )
                    : state?.step == 'success' &&
                    (
                        <>
                            <ChangePasswordSuccess />
                        </>
                    )
                }
            </div>
        </>
    );
}

export default ForgetPasswordLayout;