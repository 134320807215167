import { Button, List, Modal, Space, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { select_promotion_code } from '../../actions/auth.action';
import MasterPromoCodeList from './master_promo_code_list';
import promoCodeService from '../../services/affiliate/promo_code.service';

const MasterPromoCode = () => {
    const authRedux = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [state, setState] = useState({
        loading : false,
        promo_select_modal : false,

        selected_promotion_code_id : null, // for pre-select
        select_loading : false,
    })

    const onChangeSelectedPromotionCode = async () => {
        setState(state => ({...state, select_loading : true, }))
        // set local storage
        // fetch from backend
        const get_promotion_code = await getPromotionCode(state?.selected_promotion_code_id);
        
        setTimeout(() => {
            localStorage.setItem("selected_promotion_code", JSON.stringify(get_promotion_code));
            dispatch(select_promotion_code(get_promotion_code));
            setState(state => ({...state, select_loading : false, promo_select_modal : false, }))
        }, 500);
        
    }

    const fetchAffiliatePromoCode = async () => {
        const { promotion_codes } = authRedux?.auth;

        // get item
        const local_storage_promotion_code = localStorage?.getItem("selected_promotion_code");
        let default_promotion_code_id;
        if(local_storage_promotion_code){
            const local_promotion_code_obj = JSON.parse(local_storage_promotion_code);
            default_promotion_code_id = local_promotion_code_obj?.promotion_code_id;
        }else{
            const default_promotion_code = promotion_codes?.length > 0 ? promotion_codes[0] : null;
            default_promotion_code_id = default_promotion_code?.promotion_code_id;
        }

        // fetch from backend
        const get_promotion_code = await getPromotionCode(default_promotion_code_id);

        // set local storage
        localStorage.setItem("selected_promotion_code", JSON.stringify(get_promotion_code));
        dispatch(select_promotion_code(get_promotion_code));
        setState(state => ({...state, selected_promotion_code_id : get_promotion_code?.promotion_code_id, loading : false,}))
    }

    const getPromotionCode = async (promotion_code_id) => {
        // fetch from backend
        const promotionCodeResponse = await promoCodeService?.getPromoCodeByAffiliate({
            promotion_code_id : promotion_code_id,
        });
        const get_promotion_code = promotionCodeResponse?.promotion_codes?.length > 0 ? promotionCodeResponse?.promotion_codes[0] : null;
        return get_promotion_code;
    }

    useEffect(() => {
        fetchAffiliatePromoCode();
    }, [])
    
    return (
        <>
            <Button
            onClick={() => setState(state => ({...state, promo_select_modal : true,}))}
            >
                <Space>
                    <div>
                        <Tag color='orange' style={{border:'none',}}>
                            {authRedux?.selected_promotion_code?.promo_code}
                        </Tag>
                    </div>
                    <div>
                        <span style={{ color:'var(--secondary-text-color)', fontSize:12, }}>
                            {authRedux?.selected_promotion_code?.parks?.name}
                        </span>
                    </div>
                </Space>
            </Button>


            {/* Modal Promotion Code Selection */}
            <Modal
            open={state?.promo_select_modal}
            title="Select Promotion Code"
            confirmLoading={state?.select_loading}
            onOk={() => onChangeSelectedPromotionCode()}
            onCancel={() => setState(state => ({...state, promo_select_modal : false,}))}
            >
                <MasterPromoCodeList
                modal={state?.promo_select_modal}
                selected_promotion_code_id={state?.selected_promotion_code_id}
                onSelectPromoCode={(promotion_code_id) => setState(state => ({...state, selected_promotion_code_id : promotion_code_id}))}
                />
            </Modal>
        </>
    );
}

export default MasterPromoCode;