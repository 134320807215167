import { DatePicker, Divider, Space, Spin, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import TicketLayout from '../charts/overview/ticket_layout';
import { useSelector } from 'react-redux';
import { CaretDownFilled } from '@ant-design/icons';

import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';
import FilterBullet from '../../../helpers/components/filter_bullet';
import moment from 'moment';
import saleService from '../../../services/affiliate/sale.service';
import { LoadingOutlined, } from '@ant-design/icons';
import SaleLayout from '../charts/sale/sale_layout';
import TicketList from '../../sale/components/ticket_list';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import serviceHelper from '../../../helpers/functions/service.helper';

const { RangePicker } = DatePicker;
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement);
const HomeLayout = ({ default_filter, }) => {
    const authRedux = useSelector(state => state.auth);
    const promotion_code = authRedux?.selected_promotion_code;
    const [state, setState] = useState({
        selected_tab : 'overview',

        // chart
        loading : false,
        total_date_breakdown_sales : [],
        total_sales : null,

        compare : null,
    });

    const [filter, setFilter] = useState({
        start_date : default_filter?.start_date ?? moment()?.subtract(6, 'days')?.format("YYYY-MM-DD") , // if no default filter, get a week before
        end_date : default_filter?.end_date ?? moment()?.format("YYYY-MM-DD"),
    })

    const changeDate = (date) => {
        if(date){
            const start_date = dayjs(date[0])?.format("YYYY-MM-DD");
            const end_date = dayjs(date[1])?.format("YYYY-MM-DD");

            setFilter(filter => ({...filter, start_date : start_date, end_date : end_date}));
            changeFilter('start_date', start_date);
            changeFilter('end_date', end_date);
        }else{
            setFilter(filter => ({...filter, start_date : null, end_date : null}));
            changeFilter('start_date', null);
            changeFilter('end_date', null);
        }
    }

    // for changing filter
    const changeFilter = (key, value) => {
        let generate_filter = default_filter ?? {};
        generate_filter[key] = value;
        
        const queryString = serviceHelper?.queryString(generate_filter);
        window.history.replaceState({}, '', `${window.location.pathname}?${queryString}`);
    }

    const generateReport = async () => {
        setState(state => ({...state, loading : true,}));
        // get last week
        // compare date
        const date_diff = moment(filter?.end_date)?.diff(moment(filter?.start_date), 'days') + 1;

        const previousReportResponse = await saleService?.generateReport({
            start_date : moment(filter?.start_date)?.subtract(date_diff, 'd')?.format("YYYY-MM-DD"),
            end_date : moment(filter?.end_date)?.subtract(date_diff, 'd')?.format("YYYY-MM-DD"),
            promotion_code_id : promotion_code?.promotion_code_id,
        });

        const reportResponse = await saleService?.generateReport({
            start_date : filter?.start_date,
            end_date : filter?.end_date,
            promotion_code_id : promotion_code?.promotion_code_id,
        });
        

        const { total_date_breakdown_sales, total_sales, } = reportResponse;
        const { total_sales : previous_total_sales } = previousReportResponse;

        // caculate sales increased
        const sale_count_increased = previous_total_sales?.ticket_user_count > 0 ? ((total_sales?.ticket_user_count - previous_total_sales?.ticket_user_count) / previous_total_sales?.ticket_user_count) : 0;
        const add_on_count_increased = previous_total_sales?.add_on_count > 0 ? ((total_sales?.add_on_count - previous_total_sales?.add_on_count) / previous_total_sales?.add_on_count) : 0;
        const sale_amount_increased = previous_total_sales?.sales_amount > 0 ? ((total_sales?.sales_amount - previous_total_sales?.sales_amount) / previous_total_sales.sales_amount) : 0;
        const add_on_amount_increased = previous_total_sales?.add_on_amount > 0 ? ((total_sales?.add_on_amount - previous_total_sales?.add_on_amount) / previous_total_sales?.add_on_amount) : 0;
        const compare = {
            sale_count_increased,
            add_on_count_increased,
            sale_amount_increased,
            add_on_amount_increased,
        }
        setState(state => ({...state, loading : false, total_date_breakdown_sales : total_date_breakdown_sales, total_sales : total_sales, compare : compare, }));
    }

    useEffect(() => {
        promotion_code &&
            generateReport();
    }, [promotion_code, filter,])
    
    return (
        <>
            <div className='mobile-padding-top-12'>
                <div>
                    <div style={{textAlign:'start',}}>
                        <span className='header' style={{fontSize:24, fontWeight:'bold',}}>Hello, {authRedux?.auth?.full_name ?? authRedux?.auth?.username}</span>
                    </div>
                </div>
                
                <div>
                    {
                        authRedux?.selected_promotion_code ?
                        (
                            <>
                                <div style={{marginTop:24, flexGrow : 1,}}>
                                    <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                                        <Tabs
                                        className='chart-tabs'
                                        defaultValue={state?.selected_tab}
                                        onChange={(key) =>setState(state => ({...state, selected_tab : key, }))}
                                        items={[{
                                            key : 'overview',
                                            label : 'Overview',
                                            children: (
                                                <>
                                                    <TicketLayout
                                                    total_date_breakdown_sales={state?.total_date_breakdown_sales}
                                                    total_sales={state?.total_sales}
                                                    compare={state?.compare}
                                                    />
                                                    <Divider />
                                                    <SaleLayout
                                                    total_date_breakdown_sales={state?.total_date_breakdown_sales}
                                                    total_sales={state?.total_sales}
                                                    compare={state?.compare}
                                                    park={promotion_code?.parks}
                                                    />
                                                </>
                                            ),
                                        },
                                        ]}
                                        tabBarExtraContent={{
                                            right : (
                                                <>
                                                    <Space>
                                                        <FilterBullet
                                                        popover={
                                                            <>
                                                                <div style={{padding:12,}}>
                                                                    <RangePicker
                                                                    onChange={(e) => changeDate(e)} format={"YYYY-MM-DD"} 
                                                                    />
                                                                </div>
                                                            </>
                                                        }
                                                        >
                                                            <div>
                                                                <Space>
                                                                    <div>
                                                                        <span>
                                                                            Date
                                                                            <span style={{fontSize:12, fontWeight:500, color:'var(--secondary-text-color)'}}>
                                                                                {filter?.start_date && filter?.end_date && `: ${filter?.start_date}-${filter?.end_date}`}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        <CaretDownFilled style={{color:'#a0aec0'}} />
                                                                    </div>
                                                                </Space>
                                                            </div>
                                                        </FilterBullet>
                                                    </Space>
                                                </>
                                            )
                                        }}
                                        />
                                    </Spin>
                                </div>

                                <Divider />

                                <div>
                                    <div style={{display:'flex', alignItems:'center',}}>
                                        <div>
                                            <PageHeaderComponent title={(<span style={{fontSize:16, color : 'var(--secondary-text-color)'}}>Sale Detail</span>)} textAlign={'start'}/>
                                        </div>
                                        <div style={{marginLeft:'auto',}}>
                                            <Link to="/detail">
                                                <span>See More</span>
                                            </Link>
                                        </div>
                                    </div>
                                    
                                    <div style={{marginTop:24,}}>
                                        <TicketList display_size={5} default_filter={{}} />
                                    </div>
                                </div>
                            </>
                        )
                        :
                        (
                            <>
                                <div style={{marginTop:24, flexGrow : 1,}}>
                                    <span style={{color : 'var(--secondary-text-color)',}}>Please select promotion code</span>
                                </div>
                            </>
                        )
                    }
                    
                </div>
            </div>
        </>
    );
}

export default HomeLayout;