import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function generateReport(query){
    const queryString = serviceHelper.queryString(query);
    const reportResponse = await axios.get(`affiliate/report?${queryString}`, { withCredentials : true });
    return reportResponse?.data;
}

async function ticketListing(query){
    const queryString = serviceHelper?.queryString(query);
    const ticketListingResponse = await axios.get(`affiliate/ticket/listing?${queryString}`, { withCredentials : true, });
    return ticketListingResponse?.data;
}

export default {
    generateReport,
    ticketListing,
}