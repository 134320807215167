import React, { useEffect } from 'react';
import LayoutIndex from '../layouts';
import AppRouter from './app.router';
import PublicRoute from './public.router';
import { Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Component
import LoginIndex from '../containers/login';
import RegisterIndex from '../containers/login/register';
import ForgetIndex from '../containers/login/forget';

const AuthRouter = () => {
    const authRedux = useSelector(state => state?.auth);

    useEffect(() => {
        // refresh when promotion code is changed
    }, [authRedux != null && authRedux?.selected_promotion_code?.promotion_code_id]);
    return (
        <>
            <Switch>
                <PublicRoute restricted={false} component={LoginIndex} path="/login"></PublicRoute>
                <PublicRoute restricted={false} component={RegisterIndex} path="/register/:affiliate_code"></PublicRoute>
                <PublicRoute restricted={false} component={ForgetIndex} path="/forget"></PublicRoute>
            </Switch>

            <AppRouter />
        </>
    );
}

export default AuthRouter;