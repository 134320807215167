import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import LoginLayout from './components/login_layout';
import Register from './components/register';
import { useParams } from 'react-router-dom';

const RegisterIndex = () => {
    const { affiliate_code } = useParams();
    return (
        <>
            <div className='content-layout'>
                <ContainerComponent span={10}>
                    <LoginLayout>
                        <Register affiliate_code={affiliate_code}/>
                    </LoginLayout>
                </ContainerComponent>
            </div>
        </>
    );
}

export default RegisterIndex;