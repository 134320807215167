export const set_auth = (auth,) => {
    return {
        type : 'SET_AUTH',
        auth,
    };
}

export const select_promotion_code = (promotion_code) => {
    return {
        type : 'SELECT_PROMOTION_CODE',
        promotion_code,
    }
}