import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import { Alert, Button, Form } from 'antd';
import { Link } from 'react-router-dom';
import authService from '../../../services/affiliate/auth.service';

const ForgetPasswordOtp = ({ nextStep, email, affiliate_account_id }) => {
    const [form] = Form.useForm();
    const [otp, setOtp] = useState(null);
    const [state, setState] = useState({
        loading : false,
        invalid_code : false,
    })

    const handleOtp = async (otp) => {
        setOtp(otp);
    }

    const verifyCode = async () => {
        setState(state => ({...state, loading : true, }));
        const affiliateCodeResponse = await authService?.getAffiliateByCode(otp, {
            affiliate_code_type : 'FORGETPASS',
            affiliate_account_id : affiliate_account_id,
        }).catch((err) => {
            setState(state => ({...state, loading : false,}));
        });

        setTimeout(() => {
            const { affiliate } = affiliateCodeResponse;

            if(affiliate){
                setState(state => ({...state, loading : false, invalid_code : false, }));
                affiliate && nextStep('password', { otp : otp, });
            }else{
                setState(state => ({...state, loading : false, invalid_code : true, }));
            }
            
        }, 500);
        
        
    }

    return (
        <>
             <div>
                <div>
                    <Form
                    form={form}
                    onFinish={verifyCode}
                    layout='vertical'
                    // size={20}
                    >
                        <div style={{display:'flex', flexDirection : 'column', height: 450, }}>
                            <div>
                                <div style={{marginTop:32,}}>
                                    <div style={{textAlign:'start',}}>
                                        <span
                                        className='ticket-label ticket-title h1'
                                        style={{
                                            fontWeight:500,
                                            fontSize: 20,
                    
                                        }}
                                        >
                                            Forget Password
                                        </span>
                                    </div>
                                    <div style={{textAlign:'start',}}>
                                        <span style={{color:'var(--secondary-text-color)', fontSize:12,}}>Verification code has been sent to your {email}, enter the verification code in box below.</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{marginTop: 32,}}>
                                <Form.Item>
                                    <OtpInput
                                    containerStyle={{justifyContent:'center'}}
                                    inputStyle={{width:'3rem', height:'3rem', margin : '0 0.8rem', fontSize:'2rem', borderRadius:4, border:'1px solid #d9d9d9'}}
                                    errorStyle={{border:'1px solid red'}}
                                    value={otp}
                                    onChange={handleOtp}
                                    numInputs={6}
                                    // renderSeparator={<span>-</span>}
                                    renderInput={(props, index) => {
                                        return (
                                            <>
                                                <EntranceMotion delay={(index * 0.05)}><input {...props} /></EntranceMotion>
                                            </>
                                        )
                                    }}
                                    />
                                </Form.Item>
                                {
                                    state?.invalid_code &&
                                    (
                                        <>
                                            <EntranceMotion>
                                                <Alert type='error' message={'Invalid verification code, please try again.'} />
                                            </EntranceMotion>
                                        </>
                                    )
                                }
                            </div>

                            <div style={{marginTop:'auto',}}>
                                <Form.Item>
                                    <Button loading={state?.loading} htmlType='submit' type='primary' size='large' style={{width:'100%'}}>
                                        Continue
                                    </Button>
                                </Form.Item>
                                <div>
                                    <Link to="/login"><div ><span style={{fontSize:12, color:'var(--main-color)',}}>Back to login</span></div></Link>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default ForgetPasswordOtp;