import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import LoginLayout from './components/login_layout';
import ForgetPasswordLayout from './forget_passwords/forget_password_layout';

const ForgetIndex = () => {
    return (
        <>
            <div className='content-layout'>
                <ContainerComponent span={10}>
                    <LoginLayout>
                        <ForgetPasswordLayout />
                    </LoginLayout>
                </ContainerComponent>
            </div>
        </>
    );
}

export default ForgetIndex;