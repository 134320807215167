import { Button, Layout, Space } from 'antd';
import React, { useState } from 'react';
import MasterHeader from './master_header';
import MasterMenu from './master_menu';
import globalHelper from '../../helpers/functions/global.helper';
import { MenuOutlined } from '@ant-design/icons';

const { Header, Content, Sider } = Layout;
const MasterLayout = ({children}) => {
    const [state, setState] = useState({
        menu_collapsed : globalHelper?.isMobileScreen() ? true : false,
    })

    const onCollapsed = (is_collapse) => {
        setState(state => ({...state, menu_collapsed : is_collapse}));
    }

    return (
        <>
            <Layout>
                <Sider theme='light' collapsedWidth={0} collapsed={state?.menu_collapsed} width={globalHelper?.isMobileScreen() ? '100%' : 200}>
                    <MasterMenu onCollapsed={onCollapsed} />
                </Sider>
                {
                    // if is mobile and menu collapsed, show layout
                    // if it is not mobile, show layout
                    // if it is mobile and menu collapsed, just hide layout
                    (globalHelper?.isMobileScreen() && state?.menu_collapsed) || (!globalHelper?.isMobileScreen()) ?
                    (
                        <>
                            <Layout>
                                <Header style={{background : '#fff', border:'1px solid #edf2f7', position: 'sticky', top: 0, zIndex: 100, width: '100%', paddingInline : 24}}>
                                    <div style={{display : 'flex', width : '100%', gap : (globalHelper?.isMobileScreen() ? 12 : 24),}}>
                                        <div>
                                            <Button
                                            onClick={() => onCollapsed(!state?.menu_collapsed)}
                                            type='text' icon={<MenuOutlined />}
                                            />
                                        </div>
                                        <div style={{width:'inherit'}}>
                                            <MasterHeader />
                                        </div>
                                    </div>
                                </Header>
                                <Content className='main-content' style={{paddingInline : 24}}>
                                    <div className='content'>
                                        {children}
                                    </div>
                                </Content>
                            </Layout>
                        </>
                    )
                    :
                    (
                        <>
                            <Layout>
                                <div className='content' style={{ background : '#fff', }} />
                            </Layout>
                        </>
                    )
                }
                
            </Layout>
        </>
    );
}

export default MasterLayout;