import React, { useState } from 'react';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import ContainerComponent from '../../helpers/components/container.component';
import './home.css';
import HomeLayout from './components/home_layout';
import { useLocation } from 'react-router-dom';

const HomeIndex = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [filter, setFilter] = useState({
        start_date : queryParams.get("start_date"),
        end_date : queryParams.get("end_date"),
    })

    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <HomeLayout default_filter={filter}/>
            </ContainerComponent>
        </>
    );
}

export default HomeIndex;