import { Col, Row } from 'antd';
import React from 'react';

const ContainerComponent = ({children, span, top, bottom, is_padding, }) => {
    const { innerWidth } = window;
    return (
        <>
            <Row justify='center' align='center' style={{
                width:'100%',
                marginTop:(innerWidth <= 768 ? 0 : (top ?? 'auto')),
                marginBottom : (innerWidth <= 768 ? 0 : (bottom ?? 'auto')),
                paddingInline : (is_padding ? 24 : 0)
            }}
            >
                <Col xs={24} md={(span ? (span + 4) : 22)} lg={span ? (span + 2) : 22} xl={span ?? 24} span={24}>
                    <div style={{margin:'0 auto'}}>
                        {children}
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default ContainerComponent;