import React, { useState } from 'react';
import TicketList from './ticket_list';
import { Input, Space, DatePicker } from 'antd';
import { SearchOutlined, CaretDownFilled } from '@ant-design/icons';
import FilterBullet from '../../../helpers/components/filter_bullet';
import globalHelper from '../../../helpers/functions/global.helper';
import dayjs from 'dayjs';
import serviceHelper from '../../../helpers/functions/service.helper';

const { RangePicker } = DatePicker;
const SaleLayout = ({ default_filter, }) => {
    const [timer, setTimer] = useState(null);
    const [filter, setFilter] = useState({
        search : default_filter?.search,
        start_date : default_filter?.start_date,
        end_date : default_filter?.end_date,
    })

    const changeDate = (date) => {
        if(date){
            const start_date = dayjs(date[0])?.format("YYYY-MM-DD");
            const end_date = dayjs(date[1])?.format("YYYY-MM-DD");

            setFilter(filter => ({...filter, start_date : start_date, end_date : end_date}));
            changeFilter('start_date', start_date);
            changeFilter('end_date', end_date);
        }else{
            setFilter(filter => ({...filter, start_date : null, end_date : null}));
            changeFilter('start_date', null);
            changeFilter('end_date', null);
        }
    }

    // for changing filter
    const changeFilter = (key, value) => {
        let generate_filter = default_filter;
        generate_filter[key] = value;
        
        setFilter(filter => ({...filter, page : 1,}));
        const queryString = serviceHelper?.queryString(generate_filter);
        window.history.replaceState({}, '', `${window.location.pathname}?${queryString}`);
    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
            changeFilter('search', input);
        }, 300);
        setTimer(newTimer);
    }

    return (
        <>
            <div className='mobile-padding-top-12'>
                <div className='filter-panel'>
                    <div>
                        <Input
                        onChange={(e) => doneTyping(e.target.value)}
                        name="search"
                        prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                        placeholder='Search'
                        style={{minWidth: '100%',}}
                        allowClear
                        />
                    </div>

                    <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                        <div>
                            <div className='filter-panel' style={{textAlign:'start'}}>
                                <Space direction={globalHelper?.isMobileScreen() ? 'vertical' : 'horizontal'}>
                                    <FilterBullet
                                    popover={
                                        <>
                                            <div style={{padding:12,}}>
                                                <RangePicker
                                                onChange={(e) => changeDate(e)} format={"YYYY-MM-DD"} 
                                                />
                                            </div>
                                        </>
                                    }
                                    >
                                        <div>
                                            <Space>
                                                <div>
                                                    <span>
                                                        Date
                                                        <span style={{fontSize:12, fontWeight:500, color:'var(--secondary-text-color)'}}>
                                                            {filter?.start_date && filter?.end_date && `: ${filter?.start_date}-${filter?.end_date}`}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div>
                                                    <CaretDownFilled style={{color:'#a0aec0'}} />
                                                </div>
                                            </Space>
                                        </div>
                                    </FilterBullet>
                                </Space>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{marginTop:24,}}>
                    <TicketList is_paginate filter={filter} default_filter={default_filter}/>
                </div>
            </div>
            
        </>
    );
}

export default SaleLayout;