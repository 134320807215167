import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function authAccount(){
    const authAccountResponse = await axios.get(`affiliate/account/auth`, { withCredentials : true });
    return authAccountResponse?.data;
}

async function login(params,){
    const loginResponse = await axios.post(`affiliate/account/login`, params, { withCredentials : true });
    return loginResponse?.data;
}

async function logout(){
    const logoutResponse = await axios.post(`affiliate/account/logout`, {}, { withCredentials : true, });
    return logoutResponse?.data;
}

async function getAffiliateByCode(code, query){
    const queryString = serviceHelper.queryString(query);
    const affiliateByCodeResponse = await axios.get(`affiliate/account/code/${code}?${queryString}`, { withCredentials : true, });
    return affiliateByCodeResponse?.data;
}

async function updateAffiliateAccountAndLogin(code, params){
    const updateAffiliateLoginResponse = await axios.post(`affiliate/account/code/${code}/update/login`, params, { withCredentials : true, });
    return updateAffiliateLoginResponse?.data;
}

async function checkUsernameExisting(username){
    const usernameExistingResponse = await axios.get(`affiliate/account/username/${username}/existing`, { withCredentials : true, });
    return usernameExistingResponse?.data;
}

async function forgetPasswordEmail(params){
    const forgetPasswordResponse = await axios.post(`affiliate/account/send_forget_email`, params, { withCredentials : true, });
    return forgetPasswordResponse?.data;
}

export default {
    authAccount,
    login,
    logout,
    getAffiliateByCode,
    updateAffiliateAccountAndLogin,
    checkUsernameExisting,
    forgetPasswordEmail,
}