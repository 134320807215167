import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import authService from '../../../services/affiliate/auth.service';

const ForgetPasswordChange = ({ nextStep, otp, }) => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
    })

    const updatePassword = async () => {
        setState(state => ({...state, loading : true, }));
        const { password } = form.getFieldsValue();

        const updateLoginResponse = await authService?.updateAffiliateAccountAndLogin(otp, {
            password,
        }).catch(err => {
            setState(state => ({...state, save_loading : false,}));
        });

        setTimeout(() => {
            setState(state => ({...state, loading : false, }));
            if(updateLoginResponse?.token){
                nextStep("success");
            }
        }, 500);
        
    }

    return (
        <>
            <Form
            form={form}
            onFinish={updatePassword}
            layout='vertical'
            // size={20}
            >
                <div style={{display:'flex', flexDirection : 'column', height: 450, }}>
                    <div>
                        <div style={{marginTop:32,}}>
                            <div style={{textAlign:'start',}}>
                                <span
                                className='ticket-label ticket-title h1'
                                style={{
                                    fontWeight:500,
                                    fontSize: 20,
            
                                }}
                                >
                                    Forget Password
                                </span>
                            </div>
                            <div style={{textAlign:'start',}}>
                                <span style={{color:'var(--secondary-text-color)', fontSize:12,}}>Enter a new password that you wish to change.</span>
                            </div>
                        </div>
                    </div>

                    <div style={{marginTop:42,}}>
                        <Form.Item name={'password'} label={(<><span style={{fontSize:12, color:'var(--secondary-text-color)'}}>New Password</span></>)}>
                            <Input type='password' size='large'/>
                        </Form.Item>
                    </div>

                    <div style={{marginTop:'auto',}}>
                        <Form.Item>
                            <Button loading={state?.loading} htmlType='submit' type='primary' size='large' style={{width:'100%'}}>
                                Continue
                            </Button>
                        </Form.Item>
                        <div>
                            <Link to="/login"><div ><span style={{fontSize:12, color:'var(--main-color)',}}>Back to login</span></div></Link>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
}

export default ForgetPasswordChange;