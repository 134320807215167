import { LogoutOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Dropdown, Input, Popover, Row, Space, Tag } from 'antd';
import React, { useState } from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import { Link, useHistory } from 'react-router-dom';
import IconComponent from '../../helpers/components/icon.component';
import { useSelector } from 'react-redux';
import authService from '../../services/affiliate/auth.service';
import MasterMenu from './master_menu';
import MasterPromoCode from '../promotion_codes/master_promo_code';
import globalHelper from '../../helpers/functions/global.helper';

const MasterHeader = () => {
    const { innerWidth } = window;
    const history = useHistory();
    const authRedux = useSelector(state => state?.auth);
    const [state, setState] = useState({
        search_popover : false,

        //search
        input : null,
        search_input : null,
    });

    const handleLogout = async () => {
        const logoutResponse = await authService.logout();
        //clear local storage
        if (logoutResponse) {
            history.go(0);
        }
    }


    return (
        <>
            <ContainerComponent span={24}>
                <Row>
                    <Col offset={0} xs={16} md={8} span={8}>
                        <div style={{textAlign:'start',}}>
                            <MasterPromoCode />
                           
                        </div>
                    </Col>
                    <Col span={16} xs={8} md={16} xl={16}>
                        <div style={{textAlign:'end'}}>
                            <Space size={innerWidth >= 768 ? 40 : 20}>
                                <div>
                                    <Dropdown
                                    arrow
                                    menu={{
                                        items: [
                                            {
                                                key: '1',
                                                disabled: true,
                                                style: { padding: '5px 60px' },
                                                label: (
                                                    <>
                                                        <div style={{ margin: 'auto', textAlign: 'center' }}>
                                                            <div style={{ paddingBottom: 10 }}>
                                                                <Avatar size={'large'} style={{ backgroundColor: globalHelper?.generateFixedColorFromString(authRedux?.auth?.email) }} icon={<UserOutlined />} />
                                                            </div>
                                                            <div>
                                                                <span style={{ color: '#000' }}>{authRedux?.auth?.username}</span>
                                                            </div>
                                                            <div>
                                                                <span style={{ color: 'var(--secondary-text-color)', }}>{authRedux?.auth?.email}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                ),
                                            },
                                            {
                                                type: 'divider',
                                            },
                                            {
                                                key: '2',
                                                icon: <LogoutOutlined />,
                                                onClick: handleLogout,
                                                label: (
                                                    <>
                                                        <span>
                                                            Logout
                                                        </span>
                                                    </>
                                                ),
                                            },
                                        ]
                                    }}
                                    trigger={['click']}>
                                        <Avatar style={{ backgroundColor: globalHelper?.generateFixedColorFromString(authRedux?.auth?.email), cursor:'pointer', }} icon={<UserOutlined />} />
                                    </Dropdown>
                                </div>
                                
                            </Space>
                        </div>
                    </Col>
                </Row>
            </ContainerComponent>
        </>
    );
}

export default MasterHeader;