import { Button, Card, Form, Input } from 'antd';
import React, { useState } from 'react';
import ContainerComponent from '../../../helpers/components/container.component';

const LoginLayout = ({ children }) => {
    return (
        <>
            <div className='mobile-padding-12' style={{minHeight:'100vh', display : 'flex', flexDirection:'column', justifyContent:'center',}}>
                <div style={{textAlign:'start', marginTop:-32,}}>
                    <span className='header' style={{fontWeight:'bold', fontSize:32,}}>The Cool Melon</span>
                </div>
                <div style={{marginTop:12,}}>
                    <Card className='card-shadow' style={{minHeight:500}}>
                        <ContainerComponent span={20}>
                            {children}
                        </ContainerComponent>
                    </Card>
                </div>
            </div>
        </>
    );
}

export default LoginLayout;