import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import LoginLayout from './components/login_layout';
import './login.css';
import Login from './components/login';

const LoginIndex = () => {
    return (
        <>
            <div className='content-layout'>
                <ContainerComponent span={10}>
                    <LoginLayout>
                        <Login />
                    </LoginLayout>
                </ContainerComponent>
            </div>
        </>
    );
}

export default LoginIndex;