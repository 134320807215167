import React, { useState } from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import SaleLayout from './components/sale_layout';
import { useLocation } from 'react-router-dom';

const SaleIndex = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [filter, setFilter] = useState({
        page : queryParams.get("page"),
        search : queryParams.get("search"),
        start_date : queryParams.get("start_date"),
        end_date : queryParams.get("end_date"),
    })
    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <SaleLayout default_filter={filter} />
            </ContainerComponent>
        </>
    );
}

export default SaleIndex;