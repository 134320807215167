import { Avatar, Button, Col, Menu, Row, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import IconComponent from '../../helpers/components/icon.component';
import menuService from '../../services/affiliate/menu.service';
import { Link } from 'react-router-dom';
import { LoadingOutlined, UserOutlined, } from '@ant-design/icons';
import globalHelper from '../../helpers/functions/global.helper';
import { useSelector } from 'react-redux';
import { MenuOutlined } from '@ant-design/icons';

const MasterMenu = ({search, onCollapsed, }) => {
    const authRedux = useSelector(state => state?.auth);
    const [state, setState] = useState({
        loading : false,
        menus : [],
        affiliate_user_color : null,

        // default
        default_menu_id : null,
    })

    const fetchMenuByAccess = async () => {
        setState(state => ({...state, loading : true}));
        const menuResponse = await menuService?.getMenus({search, app_code : 'AFF'});
        const { menus } = menuResponse;
        const default_menu_id = menus?.length > 0 ? menus[0]?.menu_id : null;

        // user
        const affiliate_account = (authRedux?.auth);
        const generated_color = globalHelper?.generateFixedColorFromString(affiliate_account?.email);

        setTimeout(() => {
            setState(state => ({...state, loading : false, menus : menus, default_menu_id : default_menu_id, affiliate_user_color : generated_color, }));
        }, 200);
    }

    useEffect(() => {
        fetchMenuByAccess();
    }, [search])
    return (
        <>
            {/* menu icon button */}
            {
                globalHelper?.isMobileScreen() && (
                    <>
                        <div style={{position : 'absolute', right : 12, top : 12,}}>
                            <Button onClick={() => onCollapsed(true)} type='text' icon={<MenuOutlined />} />
                        </div>
                    </>
                )
            }
            <div style={{display : 'flex', flexDirection : 'column', height : '100%', borderInlineEnd : '1px solid rgba(5, 5, 5, 0.06)' }}>
                <div style={{ height : 'var(--header-height)', display : 'flex', flexDirection:'column', justifyContent:'center', }}>
                    <span className='header' style={{ fontWeight : 'bold', fontSize: 18,}}>The Cool Melon</span>
                </div>
                <div style={{ padding : '12px 0', }}>
                    <Avatar size={'large'} style={{ backgroundColor: state?.affiliate_user_color }} icon={<UserOutlined />} />
                </div>
                <div style={{flexGrow : 1, height : '100%', }}>
                    <Menu
                    style={{height:'inherit', borderInlineEnd : 'none', }}
                    theme='light'
                    mode="inline"
                    defaultSelectedKeys={'9'}
                    items={state?.menus?.map((menu, index) => {
                        const { menu_id, name, sequence, link, } = menu;
                        return {
                            label : (
                                <>
                                    <Link to={link} {...(globalHelper?.isMobileScreen() && { onClick : () => onCollapsed(true)} )}>
                                        {name}
                                    </Link>
                                </>
                            ),
                            key : menu_id,
                        }
                    })}
                    />
                </div>
            </div>
        </>
    );
}

export default MasterMenu;