import { Popover } from 'antd';
import React from 'react';

const FilterBullet = ({ children, popover }) => {
    return (
        <>
            <Popover
            content={popover}
            trigger={['click']}
            placement='bottomLeft'
            overlayInnerStyle={{padding : 0,}}
            >
                <div style={{padding: '2px 12px', background : '#edf2f7', color:'#4a5568', borderRadius: 6, cursor:'pointer'}}>
                    {children}
                </div>
            </Popover>
        </>
    );
}

export default FilterBullet;