import { Card, List, Modal, Space, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import promoCodeService from '../../services/affiliate/promo_code.service';
import { useDispatch, useSelector } from 'react-redux';
import { select_promotion_code } from '../../actions/auth.action';

const MasterPromoCodeList = ({ modal, selected_promotion_code_id, onSelectPromoCode, }) => {
    const authRedux = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [state, setState] = useState({
        loading : false,
        promotion_codes : [],
    })

    const fetchPromotionCodesByAffiliate = async () => {
        setState(state => ({...state, loading : true, }));
        const promotionCodeByAffiliate = await promoCodeService?.getPromoCodeByAffiliate();

        const { promotion_codes } = promotionCodeByAffiliate;
        const default_promotion_code_id = authRedux?.selected_promotion_code?.promotion_code_id;
        setState(state => ({...state, loading : false, promotion_codes : promotion_codes, selected_promotion_code_id : default_promotion_code_id, }));
    }

    const onPromoCodeChange = async (promotion_code_id) => {
        onSelectPromoCode(promotion_code_id);
    }

    useEffect(() => {
        if(modal){
            fetchPromotionCodesByAffiliate();
        }
    }, [modal])

    return (
        <>
            <Space direction='vertical' size={12} style={{width : '100%'}}>
            {
                state?.promotion_codes?.map((promotion_code, index) => {
                    const { promotion_code_id, name, parks, promo_code, } = promotion_code;
                    const is_selected = selected_promotion_code_id === promotion_code_id;
                    return (
                        <div key={index} onClick={() => onPromoCodeChange(promotion_code_id)}>
                            <Card
                            className={`selection-card ${is_selected ? 'selected' : ''}`}
                            style={{border:'none', cursor:'pointer'}}
                            bodyStyle={{padding:12,}}
                            >
                                <div>
                                    <div>
                                        <Tag style={{border:'none'}} color='orange'>
                                            <span>{promo_code}</span>
                                        </Tag>
                                    </div>
                                    <div>
                                        <span style={{color : 'var(--secondary-text-color)', fontSize:12,}}>{parks?.name}</span>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    )
                })
            }
            </Space>
            {/* <List
            className='promotion_code_list'
            dataSource={state?.promotion_codes}
            renderItem={(item, index) => {
                const { promotion_code_id, name, parks, } = item;
                return (
                    <List.Item
                    onClick={() => onPromoCodeChange(promotion_code_id)}
                    className='promotion_code_list_item' key={index}
                    >
                        <div>
                            <div>
                                <Tag style={{border:'none'}} color='orange'>
                                    <span>{name}</span>
                                </Tag>
                            </div>
                            <div>
                                <span style={{color : 'var(--secondary-text-color)', fontSize:12,}}>{parks?.name}</span>
                            </div>
                        </div>
                    </List.Item>
                )
            }}
            /> */}
        </>
    );
}

export default MasterPromoCodeList;