import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getPromoCodeByAffiliate(query){
    const queryString = serviceHelper.queryString(query);
    const promotionCodeResponse = await axios.get(`affiliate/promotion_code/get?${queryString}`, { withCredentials : true });
    return promotionCodeResponse?.data;
}


export default {
    getPromoCodeByAffiliate,
}