import { Alert, Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import authService from '../../../services/affiliate/auth.service';
import EntranceMotion from '../../../helpers/components/entrance_motion';

const ForgetPasswordEmail = ({ nextStep, }) => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        no_affiliate_found : false,
    });

    const forgetPasswordEmail = async () => {
        setState(state => ({...state, loading : true, }));
        const { email } = form.getFieldsValue();
        const emailResponse = await authService?.forgetPasswordEmail({
            email : email,
        }).catch(err => ({...state, loading : false,}));
        setState(state => ({...state, loading : false, }));

        const { affiliate_account } = emailResponse;
        if(affiliate_account){
            nextStep('otp', { email : email, affiliate_account_id : affiliate_account?.affiliate_account_id, no_affiliate_found : false, });
        }else{
            setState(state => ({...state, no_affiliate_found : true, }));
        }
    }

    return (
        <>
            <div>
                <div>
                    <Form
                    form={form}
                    onFinish={forgetPasswordEmail}
                    layout='vertical'
                    // size={20}
                    >
                        <div style={{display:'flex', flexDirection : 'column', height: 450, }}>
                            <div>
                                <div style={{marginTop:32,}}>
                                    <div style={{textAlign:'start',}}>
                                        <span
                                        className='ticket-label ticket-title h1'
                                        style={{
                                            fontWeight:500,
                                            fontSize: 20,
                    
                                        }}
                                        >
                                            Forget Password
                                        </span>
                                    </div>
                                    <div style={{textAlign:'start',}}>
                                        <span style={{color:'var(--secondary-text-color)', fontSize:12,}}>Enter the email address associated with your account, and we'll send you a verification code to reset your password.</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{marginTop:42,}}>
                                <Form.Item name={'email'} label={(<><span style={{fontSize:12, color:'var(--secondary-text-color)'}}>Email</span></>)}>
                                    <Input size='large'/>
                                </Form.Item>
                                {
                                    state?.no_affiliate_found &&
                                    (
                                        <>
                                            <div>
                                                <EntranceMotion>
                                                    <Alert type='error' message="No Affiliate Found" />
                                                </EntranceMotion>
                                            </div>
                                        </>
                                    )
                                }
                            </div>

                            <div style={{marginTop:'auto',}}>
                                <Form.Item>
                                    <Button loading={state?.loading} htmlType='submit' type='primary' size='large' style={{width:'100%'}}>
                                        Continue
                                    </Button>
                                </Form.Item>
                                <div>
                                    <Link to="/login"><div ><span style={{fontSize:12, color:'var(--main-color)',}}>Back to login</span></div></Link>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default ForgetPasswordEmail;